<template>
    <Transition name="fade-in-modal" mode="out-in">
        <div
            v-if="isVisible"
            class="cookie-box fixed bottom-0 left-0 lg:bottom-8 lg:left-8 w-full lg:w-8/12 2xl:w-6/12 bg-blue-900 text-white p-8 lg:py-14 lg:px-16 rounded-t lg:rounded-lg z-[9]"
        >
            <span class="font-Inter-Bold text-xl mb-3">Cookie-k elfogadása</span>
            <div class="baselg leading-6 consent-description">
                Weboldalunk a használat megkönnyítése és működtetése céljából, valamint a felhasználói élmény
                javításának érdekében analitikai sütiket használ. További információk az
                <nuxt-link :to="localePath('privacy-policy')" target="_blank">Adatvédelmi nyilatkozatban</nuxt-link>.
            </div>
            <transition name="fade">
                <div
                    v-if="areCheckboxesVisible || !isCompact"
                    class="flex flex-wrap gap-x-8 gap-y-4 mt-6 cookie-consent-checkboxes"
                >
                    <Input v-model="storages.necessary_storage" is-checkbox label="Kötelező" />
                    <Input v-model="storages.analytics_storage" is-checkbox label="Analitika" />
                    <Input v-model="storages.personalization_storage" is-checkbox label="Preferenciák" />
                    <Input v-model="storages.ad_storage" is-checkbox label="Marketing" />
                </div>
            </transition>
            <div class="flex flex-col gap-y-6">
                <div
                    class="flex flex-col lg:flex-row justify-center lg:justify-start items-center gap-x-8 gap-y-4 mt-10"
                >
                    <div class="w-full lg:w-auto" @click="setCookies('acceptAll')">
                        <PButton label="Összes elfogadása" appearance="yellow" class="w-full lg:w-auto" />
                    </div>
                    <div
                        v-if="isCompact && !areCheckboxesVisible"
                        class="w-full lg:w-auto"
                        @click="areCheckboxesVisible = true"
                    >
                        <PButton label="További lehetőségek" appearance="skeleton" class="w-full lg:w-auto" />
                    </div>
                    <div
                        v-if="!isCompact || areCheckboxesVisible"
                        class="w-full lg:w-auto"
                        @click="setCookies('selection')"
                    >
                        <PButton label="Kiválasztottak elfogadása" appearance="skeleton" class="w-full lg:w-auto" />
                    </div>
                    <div
                        v-if="!isCompact || areCheckboxesVisible"
                        class="w-full lg:w-auto"
                        @click="setCookies('rejectAll')"
                    >
                        <PButton label="Elutasítom" appearance="skeleton" class="w-full lg:w-auto" />
                    </div>
                </div>
            </div>
            <button class="close-button z-10" @click="isVisible = false">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.7099 11.2902C12.8982 11.4785 13.004 11.7339 13.004 12.0002C13.004 12.2665 12.8982 12.5219 12.7099 12.7102C12.5216 12.8985 12.2662 13.0043 11.9999 13.0043C11.7336 13.0043 11.4782 12.8985 11.2899 12.7102L6.99994 8.41019L2.70994 12.7102C2.52164 12.8985 2.26624 13.0043 1.99994 13.0043C1.73364 13.0043 1.47825 12.8985 1.28994 12.7102C1.10164 12.5219 0.99585 12.2665 0.99585 12.0002C0.99585 11.7339 1.10164 11.4785 1.28994 11.2902L5.58994 7.00019L1.28994 2.71019C1.10164 2.52188 0.99585 2.26649 0.99585 2.00019C0.99585 1.73388 1.10164 1.47849 1.28994 1.29019C1.47825 1.10188 1.73364 0.996094 1.99994 0.996094C2.26624 0.996094 2.52164 1.10188 2.70994 1.29019L6.99994 5.59019L11.2899 1.29019C11.4782 1.10188 11.7336 0.996094 11.9999 0.996094C12.2662 0.996094 12.5216 1.10188 12.7099 1.29019C12.8982 1.47849 13.004 1.73388 13.004 2.00019C13.004 2.26649 12.8982 2.52188 12.7099 2.71019L8.40994 7.00019L12.7099 11.2902Z"
                        fill="#fff"
                    />
                </svg>
            </button>
        </div>
    </Transition>
</template>
<script>
export default {
    name: 'CookieConsentCard',
    props: {
        isCompact: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isVisible: true,
            areCheckboxesVisible: false,
            storages: {
                necessary_storage: true,
                analytics_storage: false,
                ad_storage: false,
                personalization_storage: false,
                functionality_storage: false,
                security_storage: false,
            },
        };
    },
    methods: {
        setCookies(mode) {
            this.isVisible = false;
            const options = {
                path: '/',
                maxAge: 60 * 60 * 24 * 7,
            };
            const cookieList = [{ name: 'cookie_is_active', value: 'value1', opts: options }];

            for (const key in this.storages) {
                if (Object.hasOwnProperty.call(this.storages, key)) {
                    if (mode === 'acceptAll') {
                        this.storages[key] = 'granted';
                    } else if (mode === 'rejectAll') {
                        this.storages[key] = 'denied';
                    } else if (mode === 'selection') {
                        this.storages[key] = this.storages[key] ? 'granted' : 'denied';
                    }
                }
            }

            // this.$gtag('consent', 'update', this.storages);
            localStorage.setItem('consentMode', JSON.stringify(this.storages));
            this.$cookies.setAll(cookieList);
        },
    },
};
</script>

<style>
.consent-description {
    a {
        @apply font-Inter-Bold hover:underline;
    }
}

.close-button {
    @apply absolute w-6 h-6 top-4 right-4 transition-all scale-100 hover:scale-110;
    transition: all 0.3s;

    svg {
        @apply absolute w-4 h-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

        path {
            transition: fill 0.3s;
        }
    }
}

.cookie-consent-checkboxes {
    .checkmark {
        border: 2px solid white !important;
    }
    input[type='checkbox']:checked + .checkmark {
        border: 2px solid #ffc700 !important;
    }
}

.p-button-light {
    @apply bg-yellow-100 bg-opacity-20 border hover:bg-opacity-40 border-opacity-60;
    &:hover {
        background-color: rgba(9, 160, 116, 0.4) !important;
    }
}

.p-button-transparent {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
        border-color: rgba(255, 255, 255, 0.6) !important;
    }
}
</style>
